import DragList from "./DragList";
import Simple from "./simple";
import Awesome from "./Awesome";

function App() {
	return (
	<>
		<DragList />
	<Simple/>
		{/*<Awesome />*/}
	</>
	)
}

export default App;
