import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './App.css';

const finalSpaceCharacters = {"123":[
		{
			id: 'gary',
			name: 'Gary Goodspeed',
			thumb: '/images/gary.png'
		},
		{
			id: 'cato',
			name: 'Little Cato',
			thumb: '/images/cato.png'
		},
		{
			id: 'kvn',
			name: 'KVN',
			thumb: '/images/kvn.png'
		},
		{
			id: 'mooncake',
			name: 'Mooncake',
			thumb: '/images/mooncake.png'
		},
		{
			id: 'quinn',
			name: 'Quinn Ergon',
			thumb: '/images/quinn.png'
		}
	],"321":[
		{
			id: 'gary1',
			name: 'Gary Goodspeed',
			thumb: '/images/gary.png'
		},
		{
			id: 'cato1',
			name: 'Little Cato',
			thumb: '/images/cato.png'
		},
		{
			id: 'kvn1',
			name: 'KVN',
			thumb: '/images/kvn.png'
		},
		{
			id: 'mooncake1',
			name: 'Mooncake',
			thumb: '/images/mooncake.png'
		},
		{
			id: 'quinn1',
			name: 'Quinn Ergon',
			thumb: '/images/quinn.png'
		}
	]}

const removeFromList = (list, index) => {
	const result = Array.from(list);
	const [removed] = result.splice(index, 1);
	return [removed, result];
};

const addToList = (list, index, element) => {
	const result = Array.from(list);
	result.splice(index, 0, element);
	return result;
};

function Simple() {
	const [characters, updateCharacters] = useState(finalSpaceCharacters);

	function handleOnDragEnd(result) {

		if (!result.destination) {
			return;
		}
		const listCopy = { ...characters };

		const sourceList = listCopy[result.source.droppableId];
		const [removedElement, newSourceList] = removeFromList(
			sourceList,
			result.source.index
		);
		listCopy[result.source.droppableId] = newSourceList;
		const destinationList = listCopy[result.destination.droppableId];
		listCopy[result.destination.droppableId] = addToList(
			destinationList,
			result.destination.index,
			removedElement,
		);

		updateCharacters(listCopy);

	}

	return (
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<div style={{display: 'flex'}}>
					<Droppable
						// direction="horizontal"
						isDropDisabled
						droppableId="123">
						{(provided) => (
							<div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
								{characters["123"].map(({id, name, thumb}, index) => {
									return (
										<Draggable key={id} draggableId={id} index={index}>
											{(provided) => (
												<div className="characters-div" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div className="characters-thumb">
														<img src={thumb} alt={`${name} Thumb`} />
													</div>
													<p>
														{ name }
													</p>
												</div>
											)}
										</Draggable>

									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					<Droppable
						// direction="horizontal"
						droppableId="321">
						{(provided) => (
							<div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
								{characters["321"].map(({id, name, thumb}, index) => {
									return (
										<Draggable key={id} draggableId={id} index={index}>
											{(provided) => (
												<div className="characters-div" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div className="characters-thumb">
														<img src={thumb} alt={`${name} Thumb`} />
													</div>
													<p>
														{ name }
													</p>
												</div>
											)}
										</Draggable>

									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					</div>
				</DragDropContext>
	);
}

export default Simple;
