import React  from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";

const DragDropContextContainer = styled.div`
  border: 4px solid indianred;
  border-radius: 6px;
	min-width: 100%;
	width: fit-content;
`;


const exampleElements = {'Все элементы':[
		{
			title: "Элемент 1",
			content: "Описание элемента 1",
			id: "item-1",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 2",
			content: "Описание элемента 2",
			id: "item-2",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 3",
			content: "Описание элемента 3",
			id: "item-3",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 4",
			content: "Описание элемента 4",
			id: "item-4",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 5",
			content: "Описание элемента 5",
			id: "item-5",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 6",
			content: "Описание элемента 6",
			id: "item-6",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 7",
			content: "Описание элемента 7",
			id: "item-7",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 8",
			content: "Описание элемента 8",
			id: "item-8",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 9",
			content: "Описание элемента 9",
			id: "item-9",
			prefix: "Все элементы"
		},
		{
			title: "Элемент 10",
			content: "Описание элемента 10",
			id: "item-10",
			prefix: "Все элементы"
		}
	],'Программа':[]}


const removeFromList = (list, index) => {
	const result = Array.from(list);
	const [removed] = result.splice(index, 1);
	return [removed, result];
};

const addToList = (list, index, element) => {
	const result = Array.from(list);
	result.splice(index, 0, element);
	return result;
};



function DragList() {
	const [elements, setElements] = React.useState(exampleElements);


	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const listCopy = { ...elements };
		const sourceList = listCopy[result.source.droppableId];

		const [removedElement, newSourceList] = removeFromList(
			sourceList,
			result.source.index
		);
		listCopy[result.source.droppableId] = newSourceList;
		console.log(listCopy)

		const destinationList = listCopy[result.destination.droppableId];
		listCopy[result.destination.droppableId] = addToList(
			destinationList,
			result.destination.index,
			removedElement,
		);
		console.log(listCopy)


		setElements(listCopy);

	};

	return (
		<DragDropContextContainer>
			<DragDropContext onDragEnd={onDragEnd}>
				< >
					<DraggableElement
						isDropDisabled={true}
						elements={elements["Все элементы"]}
						key="Все элементы"
						prefix="Все элементы"
					/>
					<DraggableElement
						isDropDisabled={false}
						elements={elements["Программа"]}
						key="Программа"
						prefix="Программа"
					/>
				</>
			</DragDropContext>
		</DragDropContextContainer>
	)
}

export default DragList;
