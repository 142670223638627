import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";

const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const DroppableStyles = styled.div`
  padding: 10px;
  margin: 20px ;
  border-radius: 6px;
  background: #d4d4d4;
`;

const DraggableElement = ({ prefix, elements, isDropDisabled }) => (
	<DroppableStyles>
		<ColumnHeader>{prefix}</ColumnHeader>
		<Droppable isDropDisabled={isDropDisabled}
							 direction="horizontal" droppableId={`${prefix}`}>
			{(provided) => (
				<div
					style={{display:'flex'}} {...provided.droppableProps} ref={provided.innerRef}>
					{elements.map((item, index) => (
						<ListItem key={item.id} item={item} index={index} />
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</DroppableStyles>
);

export default DraggableElement;
